// Components
import CurrencyPosition from '@/components/ui/CurrencyPosition'

export default {
  name: 'RationsListItem',
  components: { CurrencyPosition },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    /**
     * Handle click on name
     *
     * @param {string} id - item id
     */
    handleClickName(id) {
      this.$emit('onClickName', id)
    }
  }
}
