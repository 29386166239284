// Constantes
import { DEFAULT_LANGUAGE } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import DraggableList from '@/components/ui/DraggableList'
import RationFormByTabs from '@/components/elements/rations/RationFormByTabs'
import RationsListItem from '@/components/elements/rations/RationsListItem'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapActions, mapState } from 'vuex'
// Utils
import { getPathImage } from '@/utils'
import { cloneDeep, get, isNil } from 'lodash'

export default {
  name: 'RationsList',
  components: {
    CardContainer,
    DraggableList,
    RationsListItem,
    SectionInformationBox,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  computed: {
    ...mapGetters('company', ['companyData', 'companyDataConfig', 'areThereAdditionalLanguages']),
    ...mapGetters('meta', ['rationsData']),
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Obtiene las actuales raciones del establecimiento
     *
     * @return {Array}
     */
    currentRationsData() {
      return cloneDeep(this.rationsData)
    },
    /**
     * Get the options to show the screen when there are not items
     *
     * @return {Object} - objeto opciones para mostrar
     */
    sectionInformationOptions() {
      return {
        media: getPathImage('svg/categories.svg'),
        title: 'Tipos de raciones',
        description: 'Aún no has creado ningún tipo de ración',
        buttonLabel: 'Crear tipo de ración ¡ahora!'
      }
    },
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  methods: {
    ...mapActions('meta', ['updateRationDataById']),
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      await Promise.all(
        items.map(async (item, index) => {
          // Actualizamos en BD y en local
          await this.updateRationDataById({ id: item.id, data: { order: index } })
        })
      )
    },
    /**
     * Abre un "dialog" para crear/editar una categoría
     *
     * @param {string} id - UID category en base de datos
     */
    handleRationFormDialog(id = null) {
      // Pestañas adicionales de configuración a mostrar en el formulario
      const tabs = [
        {
          id: 'basic',
          options: {
            additionalLanguages: get(this.companyDataConfig, 'additionalLanguages', []),
            currency: get(this.companyDataConfig, 'currency', null),
            defaultLanguage: get(this.companyDataConfig, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        }
      ]

      // Pestaña de traducciones
      if (this.areThereAdditionalLanguages) {
        tabs.push({
          id: 'translation',
          options: {
            additionalLanguages: get(this.companyDataConfig, 'additionalLanguages', []),
            defaultLanguage: get(this.companyDataConfig, 'defaultLanguage', DEFAULT_LANGUAGE)
          }
        })
      }

      this.modifyAppDialog({
        title: isNil(id) ? 'Crear nueva ración' : 'Editar ración',
        contentComponent: RationFormByTabs,
        contentComponentProps: {
          id,
          model: 'company',
          modelId: this.companyData.id,
          tabs
        },
        hideActionButtons: true,
        visible: true
      })
    }
  }
}
